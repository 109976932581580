import { DeleteDialogService } from 'services/delete-dialog-service';
import { ErrorService } from "services/error-service";
import { Router } from "aurelia-router";
import { autoinject } from "aurelia-framework";
import { Models } from "models/core";
import { ToastService } from "services/toast-service";
import { EventAggregator } from "aurelia-event-aggregator"; 
import { EquipmentTypeService } from "services/equipment-type-service";
import { LoopPositionVerticalService } from "services/loop-position-vertical-service";

@autoinject
export class loopPositionVerticalDetail {
  private looppositionvertical: Models.LoopPositionVertical;

  constructor(
    private loopPositionVerticalService: LoopPositionVerticalService,
    private errorService: ErrorService,
    private router: Router,
    private toastService: ToastService,
    private eventAggregator: EventAggregator,
    private deleteDialogService: DeleteDialogService
  ) {}

  activate(params) {
    this.loopPositionVerticalService
      .get(params.Id)
      .then(loopPositionVertical => {
        this.looppositionvertical = loopPositionVertical;
      })
      .catch(err => this.errorService.handleError(err));
  }

  updateLoopPositionVertical() {
    this.loopPositionVerticalService
      .put(this.looppositionvertical, this.looppositionvertical.Id)
      .then(res => {
        this.eventAggregator.publish("loopPositionVerticalListReset", 1);
        this.toastService.showSuccess("looppositionvertical.updated");
        this.router.navigateToRoute("loop-position-vertical-list");
      })
      .catch(err => this.errorService.handleError(err));
  }

  deleteLoopPositionVertical() {
    this.deleteDialogService.confirmBeforeDelete(
      () => {
        this.loopPositionVerticalService
          .delete(this.looppositionvertical.Id)
          .then(res => {
            this.eventAggregator.publish("loopPositionVerticalListReset", 1);
            this.toastService.showSuccess("looppositionvertical.deleted");
            this.router.navigateToRoute("loop-position-vertical-list");
          })
          .catch(err => this.errorService.handleError(err));
        }
      );
  }
  
  gotoParentView(){
    this.eventAggregator.publish("loopPositionVerticalListReset", 1);
    this.router.navigateToRoute("loop-position-vertical-list");
  }
}
