import { autoinject, bindable } from 'aurelia-framework';
import { AsyncErrorHandler } from 'lib/ui';
import { CreateUpdateMaterialImpregnationUptakeFactors } from 'models';
import { Models } from 'models/core';
import { ProductService } from 'services/product-service';
import { StandardValidationBuilder } from 'lib/validation';
import { ValidationController, Validator } from 'aurelia-validation';

@autoinject
export class ImpregnationTypeForm {
  @bindable() private impregnationtype: Models.ImpregnationType;

  @bindable()
  protected validationController: ValidationController;

  private products: Array<Models.Product>;

  private materialUptakeFactorsToDelete: Array<CreateUpdateMaterialImpregnationUptakeFactors>;

  constructor(
    private productService: ProductService,
    private validaion: StandardValidationBuilder,
    protected validator: Validator
  ) {}

  protected applyValidationRules() {
    if (!this.impregnationtype) return;

    if (!this.impregnationtype.MaterialImpregnationUptakeFactors) {
      this.impregnationtype.MaterialImpregnationUptakeFactors = [];
    }

    this.validaion
      .with(this.impregnationtype)
      .required('Name')
      .custom('NavisionProductId', 'validation.requiresAtLeastOneArticle', (value, model) => {
        if (!value && !model.DeltaProductId) return false;
        return true;
      })
      .custom('DeltaProductId', 'validation.requiresAtLeastOneArticle', (value, model) => {
        if (!value && !model.NavisionProductId) return false;
        return true;
      })
      .done();
  }

  @AsyncErrorHandler
  protected async attached() {
    await this.getAllProducts();
    if (!this.impregnationtype.MaterialImpregnationUptakeFactors) {
      this.impregnationtype.MaterialImpregnationUptakeFactors = [];
    }

    this.applyValidationRules();
  }

  @AsyncErrorHandler
  private async getAllProducts() {
    this.products = await this.productService.getAllCached();
  }

  protected setNavisionProductId(event: { detail: { value: number } }) {
    const value = event.detail.value;
    this.impregnationtype.NavisionProductId = value;
    this.setProductName(value);
  }

  protected setDeltaProductId(event: { detail: { value: number } }) {
    const value = event.detail.value;
    this.impregnationtype.DeltaProductId = value;
    this.setProductName(value);
  }

  protected addUptakeFactor() {
    if (!this.impregnationtype.MaterialImpregnationUptakeFactors?.length) {
      this.impregnationtype.MaterialImpregnationUptakeFactors = [];
    }

    this.impregnationtype.MaterialImpregnationUptakeFactors.push({
      MaterialId: undefined,
      UptakeFactor: 1,
    });
  }

  protected removeUptakeFactor(index: number) {
    if (!this.impregnationtype.MaterialImpregnationUptakeFactors?.length) return;
    const miuf = this.impregnationtype.MaterialImpregnationUptakeFactors.splice(index, 1);
    this.materialUptakeFactorsToDelete = this.materialUptakeFactorsToDelete || [];
    this.materialUptakeFactorsToDelete.push(miuf[0]);
  }

  setProductName(productId: string | number) {
    if (!this.impregnationtype.Name || this.impregnationtype.Name === '') {
      const product = this.products.find((x) => x.Id.toString() == productId);
      if (product) {
        this.impregnationtype.Name = product.Name;
        this.impregnationtype.NameEn = product.Name;
        this.impregnationtype.NameEs = product.Name;
      }
    }
  }
}
