import { Router } from 'aurelia-router';
import { Models } from 'models/core';
import { ImpregnationTypeService } from 'services/impregnation-type-service';
import { autoinject } from 'aurelia-framework';
import { ToastService } from 'services/toast-service';
import { EventAggregator } from 'aurelia-event-aggregator';
import { AsyncErrorHandler } from 'lib/ui';
import { ValidationController } from 'aurelia-validation';

@autoinject
export class ImpregnationTypeNew {
  private impregnationtype: Models.ImpregnationType = new Models.ImpregnationType();
  protected isSaving = false;

  constructor(
    private impregnationTypeService: ImpregnationTypeService,
    private router: Router,
    private toastService: ToastService,
    private eventAggregator: EventAggregator,
    private validationController: ValidationController
  ) {}

  @AsyncErrorHandler
  async createImpregnationType() {
    const { valid } = await this.validationController.validate();
    if (!valid) return;
    const { Id } = await this.impregnationTypeService.post(this.impregnationtype);
    this.eventAggregator.publish('impregnationTypeListReset', 1);
    this.toastService.showSuccess('impregnationtype.created');
    this.router.navigateToRoute('impregnation-type-detail', { Id });
  }
}
