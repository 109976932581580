import { autoinject, bindable } from 'aurelia-framework';
import { Models } from 'models/core';
import { SegmentService } from 'services/segment-service';
import { UserService } from 'services/user-service';

@autoinject
export class InternalDepartmentForm {
  @bindable private internalDepartment: Models.InternalDepartment;

  private constructor(
    private userService: UserService,
    private segmentService: SegmentService
  ) {}
}
