import { Router } from 'aurelia-router';
import { autoinject } from 'aurelia-framework';
import { ImpregnationTypeService } from 'services/impregnation-type-service';
import { Models } from 'models/core';
import { ToastService } from 'services/toast-service';
import { EventAggregator } from 'aurelia-event-aggregator';
import { ConfirmDialog } from 'components/dialogs/confirm/confirm-dialog';
import { AsyncErrorHandler } from 'lib/ui';
import { ValidationController } from 'aurelia-validation';
import { plainToClassFromExist } from 'class-transformer';
import { CreateUpdateMaterialImpregnationUptakeFactors } from 'models';

@autoinject
export class impregnationTypeDetail {
  private impregnationtype: Models.ImpregnationType;
  private originalImpregnationType: Models.ImpregnationType;

  constructor(
    private impregnationTypeService: ImpregnationTypeService,
    private router: Router,
    private toastService: ToastService,
    private eventAggregator: EventAggregator,
    private confirmDialog: ConfirmDialog,
    private validationController: ValidationController
  ) { }

  async activate(params: { Id: number }) {
    this.impregnationtype = await this.impregnationTypeService.get(
      `${params.Id}?$expand=MaterialImpregnationUptakeFactors`
    );
    if (!this.impregnationtype.MaterialImpregnationUptakeFactors) {
      this.impregnationtype.MaterialImpregnationUptakeFactors = [];
    }
    this.originalImpregnationType = JSON.parse(JSON.stringify(this.impregnationtype));
  }

  @AsyncErrorHandler
  async updateImpregnationType() {
    const materialUptakeFactorsToDelete = this.originalImpregnationType.MaterialImpregnationUptakeFactors.filter(
      (miuf) =>
        !this.impregnationtype.MaterialImpregnationUptakeFactors.some((trackedMiuf) => trackedMiuf.Id === miuf.Id)
    );

    const { valid } = await this.validationController.validate();

    if (!valid) {
      return;
    }

    if (materialUptakeFactorsToDelete?.length) {
      await Promise.all(
        materialUptakeFactorsToDelete.map((miuf) =>
          this.impregnationTypeService.removeMaterialUptakeFactor(this.impregnationtype.Id, miuf.Id)
        )
      );
    }

    const dto = this.impregnationtype;
    dto.MaterialImpregnationUptakeFactors = dto.MaterialImpregnationUptakeFactors.map((miuf) =>
      plainToClassFromExist(new CreateUpdateMaterialImpregnationUptakeFactors(), miuf)
    );
    await this.impregnationTypeService.put(dto, this.impregnationtype.Id);
    this.eventAggregator.publish('impregnationTypeListReset', 1);
    this.toastService.showSuccess('impregnationtype.updated');
    this.router.navigateToRoute('impregnation-type-list');
  }

  @AsyncErrorHandler
  async deleteImpregnationType() {
    const confirmation = await this.confirmDialog.confirmDelete('impregnationtype.delete');
    if (!confirmation) return;
    await this.impregnationTypeService.delete(this.impregnationtype.Id);

    this.eventAggregator.publish('impregnationTypeListReset', 1);
    this.toastService.showSuccess('impregnationtype.deleted');
    this.router.navigateToRoute('impregnation-type-list');
  }

  gotoParentView() {
    this.eventAggregator.publish('impregnationTypeListReset', 1);
    this.router.navigateToRoute('impregnation-type-list');
  }
}
