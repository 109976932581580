import { EventAggregator } from 'aurelia-event-aggregator';
import { autoinject } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { Models } from 'models/core';
import { ErrorService } from 'services/error-service';
import { InternalDepartmentService } from 'services/internal-department-service';
import { ToastService } from 'services/toast-service';

@autoinject
export class InternalDepartmentNew {
  private internalDepartment: Models.InternalDepartment = new Models.InternalDepartment();

  private constructor(
    private errorService: ErrorService,
    private eventAggregator: EventAggregator,
    private router: Router,
    private toastService: ToastService,
    private internalDepartmentService: InternalDepartmentService
  ) {}

  private createInternalDepartment() {
    this.internalDepartmentService
      .post(this.internalDepartment)
      .then((res) => {
        this.eventAggregator.publish('internalDepartmentListReset', 1);
        this.toastService.showSuccess('internalDepartment.created');
        this.router.navigateToRoute('internal-department-detail', { Id: res.Id });
      })
      .catch((err) => this.errorService.handleError(err));
  }
}
