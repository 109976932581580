import { DeleteDialogService } from 'services/delete-dialog-service';
import { ErrorService } from "services/error-service";
import { Router } from "aurelia-router";
import { autoinject } from "aurelia-framework";
import { LoopTypeService } from "services/loop-type-service";
import { Models } from "models/core";
import { ToastService } from "services/toast-service";
import { EventAggregator } from "aurelia-event-aggregator"; 

@autoinject
export class loopTypeDetail {
  private looptype: Models.LoopType;

  constructor(
    private loopTypeService: LoopTypeService,
    private errorService: ErrorService,
    private router: Router,
    private toastService: ToastService,
    private eventAggregator: EventAggregator,
    private deleteDialogService: DeleteDialogService
  ) {}

  activate(params) {
    this.loopTypeService
      .get(params.Id)
      .then(loopType => {
        this.looptype = loopType;
      })
      .catch(err => this.errorService.handleError(err));
  }

  updateLoopType() {
    this.loopTypeService
      .put(this.looptype, this.looptype.Id)
      .then(res => {
        this.eventAggregator.publish("loopTypeListReset", 1);
        this.toastService.showSuccess("looptype.updated");
        this.router.navigateToRoute("loop-type-list");
      })
      .catch(err => this.errorService.handleError(err));
  }

  deleteLoopType() {
    this.deleteDialogService.confirmBeforeDelete(
      () => {
        this.loopTypeService
          .delete(this.looptype.Id)
          .then(res => {
            this.eventAggregator.publish("loopTypeListReset", 1);
            this.toastService.showSuccess("looptype.deleted");
            this.router.navigateToRoute("loop-type-list");
          })
          .catch(err => this.errorService.handleError(err));
        }
      );
  }
  gotoParentView(){
    this.eventAggregator.publish("loopTypeListReset", 1);
    this.router.navigateToRoute("loop-type-list");   
  }
}
