import { EventAggregator } from 'aurelia-event-aggregator';
import { autoinject } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { AsyncErrorHandler, ViewLoadingFader } from 'lib/ui';
import { Models } from 'models/core';
import { ImpregnationTypeService } from 'services/impregnation-type-service';

@autoinject
export class ImpregnationTypeList {
  protected tableData: Array<Models.ImpregnationType>;
  protected activeTableRow: number;
  protected ready: boolean = false;

  constructor(
    protected element: Element,
    private impregnationTypeService: ImpregnationTypeService,
    private router: Router,
    private eventAggregator: EventAggregator
  ) {}

  protected activate(params: { Id: number }) {
    if (params.Id) {
      this.activeTableRow = params.Id;
    } else {
      this.activeTableRow = 0;
    }
  }

  @ViewLoadingFader
  protected async attached() {
    this.eventAggregator.subscribe('impregnationTypeListReset', () => {
      this.activeTableRow = null;
      return this.updateView();
    });
    await this.updateView();
  }

  @AsyncErrorHandler
  private async updateView() {
    const res = await this.impregnationTypeService.getAll('?$orderby=Name&$expand=NavisionProduct,DeltaProduct');
    this.ready = true;
    this.tableData = res;
  }

  protected gotoDetailView(id: number) {
    this.activeTableRow = id;
    this.router.navigateToRoute('impregnation-type-detail', { Id: id });
  }
}
