import { ErrorService } from "services/error-service";
import { Router } from "aurelia-router";
import { Models } from "models/core";
import { autoinject } from "aurelia-framework";
import { ToastService } from "services/toast-service";
import { EventAggregator } from "aurelia-event-aggregator";
import { LoopPositionHorizontalService } from "services/loop-position-horizontal-service";


@autoinject
export class LoopPositionHorizontalNew {
  private looppositionhorizontal: Models.LoopPositionHorizontal = new Models.LoopPositionHorizontal();

  constructor(
    private loopPositionHorizontalService: LoopPositionHorizontalService, 
    private router: Router,
    private toastService: ToastService,
    private errorService: ErrorService,
    private eventAggregator: EventAggregator
  ) {}

  createLoopPositionHorizontal() {
    this.loopPositionHorizontalService
      .post(this.looppositionhorizontal)
      .then(res => {
        this.eventAggregator.publish("loopPositionHorizontalListReset", 1);
        this.toastService.showSuccess("looppositionhorizontal.created");
        this.router.navigateToRoute("loop-position-horizontal-detail", { Id: res.Id });
      })
      .catch(err => this.errorService.handleError(err));
  }
}
