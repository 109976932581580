import { LaceThreadTypeService } from 'services/lace-thread-type-service';
import { LaceThreadDimensionService } from 'services/lace-thread-dimension-service';
import { EventAggregator } from 'aurelia-event-aggregator';
import { bindable, bindingMode, containerless } from 'aurelia-framework';
import { autoinject } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { Models } from 'models/core';
import { ErrorService } from 'services/error-service';
import { LaceThreadCategoryService } from 'services/lace-thread-category-service';
import { LaceThreadService } from 'services/lace-thread-service';
import { ToastService } from 'services/toast-service';

@containerless
@autoinject
export class LaceThreadFormInlineEdit {
  @bindable private lacethread: Models.LaceThread;
  
  private lacethreadbreakingstrength: number;

  constructor(
    private laceThreadService: LaceThreadService,
    private laceThreadCategoryService: LaceThreadCategoryService,
    private laceThreadDimensionService: LaceThreadDimensionService,
    private laceThreadTypeService: LaceThreadTypeService,
    private eventAggregator: EventAggregator,
    private router: Router,
    private toastService: ToastService,
    private errorService: ErrorService
  ) {}

  private attached() {
  }
  
  private updateLaceThread() {
    this.lacethread.LaceThreadCategory = null;
    this.lacethread.LaceThreadDimension = null;
    this.lacethread.LaceThreadType = null;
    this.laceThreadService
      .put(this.lacethread, this.lacethread.Id)
      .then((res) => {
        this.eventAggregator.publish('laceThreadListReset', 1);
        this.eventAggregator.publish('laceThreadFormEditClose', 1);
        this.toastService.showSuccess('laceThread.updated');
      })
      .catch((err) => this.errorService.handleError(err));
  }

  private deleteLaceThread() {
    this.laceThreadService
      .delete(this.lacethread.Id)
      .then((res) => {
        this.eventAggregator.publish('laceThreadFormEditClose', 1);
        this.eventAggregator.publish('laceThreadListReset', 1);
        this.toastService.showSuccess('laceThread.deleted');
      })
      .catch((err) => this.errorService.handleError(err));
  }

  private cancelEdit() {
    this.eventAggregator.publish('laceThreadFormEditClose', 1);
    this.eventAggregator.publish('laceThreadListReset', 1);
  }

}
