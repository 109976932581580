import { DeleteDialogService } from 'services/delete-dialog-service';
import { ErrorService } from "services/error-service";
import { Router } from "aurelia-router";
import { autoinject } from "aurelia-framework";
import { LaceThreadTypeService } from "services/lace-thread-type-service";
import { Models } from "models/core";
import { ToastService } from "services/toast-service";
import { EventAggregator } from "aurelia-event-aggregator"; 

@autoinject
export class laceThreadTypeDetail {
  private lacethreadtype: Models.LaceThreadType;

  constructor(
    private laceThreadTypeService: LaceThreadTypeService,
    private errorService: ErrorService,
    private router: Router,
    private toastService: ToastService,
    private eventAggregator: EventAggregator,
    private deleteDialogService: DeleteDialogService
  ) {}

  activate(params) {
    this.laceThreadTypeService
      .get(params.Id)
      .then(laceThreadType => {
        this.lacethreadtype = laceThreadType;
      })
      .catch(err => this.errorService.handleError(err));
  }

  updateLaceThreadType() {
    this.laceThreadTypeService
      .put(this.lacethreadtype, this.lacethreadtype.Id)
      .then(res => {
        this.eventAggregator.publish("laceThreadTypeListReset", 1);
        this.toastService.showSuccess("lacethreadtype.updated");
        this.router.navigateToRoute("lace-thread-type-list");
      })
      .catch(err => this.errorService.handleError(err));
  }

  deleteLaceThreadType() {
    this.deleteDialogService.confirmBeforeDelete(
      () => {
        this.laceThreadTypeService
          .delete(this.lacethreadtype.Id)
          .then(res => {
            this.eventAggregator.publish("laceThreadTypeListReset", 1);
            this.toastService.showSuccess("lacethreadtype.deleted");
            this.router.navigateToRoute("lace-thread-type-list");
          })
          .catch(err => this.errorService.handleError(err));
        }
      );    
  }
  
  gotoParentView(){
    this.eventAggregator.publish("laceThreadTypeListReset", 1);
    this.router.navigateToRoute("lace-thread-type-list");   
  }
}
