import { ErrorService } from "services/error-service";
import { Router } from "aurelia-router";
import { Models } from "models/core";
import { LaceThreadTypeService } from "services/lace-thread-type-service";
import { autoinject } from "aurelia-framework";
import { ToastService } from "services/toast-service";
import { EventAggregator } from "aurelia-event-aggregator";

@autoinject
export class LaceThreadTypeNew {
  private lacethreadtype: Models.LaceThreadType = new Models.LaceThreadType();

  constructor(
    private laceThreadTypeService: LaceThreadTypeService, 
    private router: Router,
    private toastService: ToastService,
    private errorService: ErrorService,
    private eventAggregator: EventAggregator
  ) {}

  createLaceThreadType() {
    this.laceThreadTypeService
      .post(this.lacethreadtype)
      .then(res => {
        this.eventAggregator.publish("laceThreadTypeListReset", 1);
        this.toastService.showSuccess("lacethreadtype.created");
        this.router.navigateToRoute("lace-thread-type-detail", { Id: res.Id });
      })
      .catch(err => this.errorService.handleError(err));
  }
}
