import { DeleteDialogService } from 'services/delete-dialog-service';
import { ErrorService } from "services/error-service";
import { Router } from "aurelia-router";
import { autoinject } from "aurelia-framework";
import { Models } from "models/core";
import { ToastService } from "services/toast-service";
import { EventAggregator } from "aurelia-event-aggregator"; 
import { EquipmentTypeService } from "services/equipment-type-service";
import { LoopPositionHorizontalService } from "services/loop-position-horizontal-service";

@autoinject
export class loopPositionHorizontalDetail {
  private looppositionhorizontal: Models.LoopPositionHorizontal;

  constructor(
    private loopPositionHorizontalService: LoopPositionHorizontalService,
    private errorService: ErrorService,
    private router: Router,
    private toastService: ToastService,
    private eventAggregator: EventAggregator,
    private deleteDialogService: DeleteDialogService
  ) {}

  activate(params) {
    this.loopPositionHorizontalService
      .get(params.Id)
      .then(loopPositionHorizontal => {
        this.looppositionhorizontal = loopPositionHorizontal;
      })
      .catch(err => this.errorService.handleError(err));
  }

  updateLoopPositionHorizontal() {
    this.loopPositionHorizontalService
      .put(this.looppositionhorizontal, this.looppositionhorizontal.Id)
      .then(res => {
        this.eventAggregator.publish("loopPositionHorizontalListReset", 1);
        this.toastService.showSuccess("looppositionhorizontal.updated");
        this.router.navigateToRoute("loop-position-horizontal-list");
      })
      .catch(err => this.errorService.handleError(err));
  }

  deleteLoopPositionHorizontal() {
    this.deleteDialogService.confirmBeforeDelete(
      () => {
        this.loopPositionHorizontalService
          .delete(this.looppositionhorizontal.Id)
          .then(res => {
            this.eventAggregator.publish("loopPositionHorizontalListReset", 1);
            this.toastService.showSuccess("looppositionhorizontal.deleted");
            this.router.navigateToRoute("loop-position-horizontal-list");
          })
          .catch(err => this.errorService.handleError(err));
        }
      );  
  }
  
  gotoParentView(){
    this.eventAggregator.publish("loopPositionHorizontalListReset", 1);
    this.router.navigateToRoute("loop-position-horizontal-list");
  }
}
