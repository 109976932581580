import { ErrorService } from "services/error-service";
import { Router } from "aurelia-router";
import { Models } from "models/core";
import { LaceThreadDimensionService } from "services/lace-thread-dimension-service";
import { autoinject } from "aurelia-framework";
import { ToastService } from "services/toast-service";
import { EventAggregator } from "aurelia-event-aggregator";

@autoinject
export class LaceThreadDimensionNew {
  private lacethreaddimension: Models.LaceThreadDimension = new Models.LaceThreadDimension();

  constructor(
    private laceThreadDimensionService: LaceThreadDimensionService, 
    private router: Router,
    private toastService: ToastService,
    private errorService: ErrorService,
    private eventAggregator: EventAggregator
  ) {}

  createLaceThreadDimension() {
    this.laceThreadDimensionService
      .post(this.lacethreaddimension)
      .then(res => {
        this.eventAggregator.publish("laceThreadDimensionListReset", 1);
        this.toastService.showSuccess("lacethreaddimension.created");
        this.router.navigateToRoute("lace-thread-dimension-detail", { Id: res.Id });
      })
      .catch(err => this.errorService.handleError(err));
  }
}
