import { DeleteDialogService } from 'services/delete-dialog-service';
import { ErrorService } from "services/error-service";
import { Router } from "aurelia-router";
import { autoinject } from "aurelia-framework";
import { LaceThreadDimensionService } from "services/lace-thread-dimension-service";
import { Models } from "models/core";
import { ToastService } from "services/toast-service";
import { EventAggregator } from "aurelia-event-aggregator"; 

@autoinject
export class laceThreadDimensionDetail {
  private lacethreaddimension: Models.LaceThreadDimension;

  constructor(
    private laceThreadDimensionService: LaceThreadDimensionService,
    private errorService: ErrorService,
    private router: Router,
    private toastService: ToastService,
    private eventAggregator: EventAggregator,
    private deleteDialogService: DeleteDialogService
  ) {}

  activate(params) {
    this.laceThreadDimensionService
      .get(params.Id)
      .then(laceThreadDimension => {
        this.lacethreaddimension = laceThreadDimension;
      })
      .catch(err => this.errorService.handleError(err));
  }

  updateLaceThreadDimension() {
    this.laceThreadDimensionService
      .put(this.lacethreaddimension, this.lacethreaddimension.Id)
      .then(res => {
        this.eventAggregator.publish("laceThreadDimensionListReset", 1);
        this.toastService.showSuccess("lacethreaddimension.updated");
        this.router.navigateToRoute("lace-thread-dimension-list");
      })
      .catch(err => this.errorService.handleError(err));
  }

  deleteLaceThreadDimension() {    
    this.deleteDialogService.confirmBeforeDelete(
      () => {         
        this.laceThreadDimensionService
          .delete(this.lacethreaddimension.Id)
          .then(res => {
            this.eventAggregator.publish("laceThreadDimensionListReset", 1);
            this.toastService.showSuccess("lacethreaddimension.deleted");
            this.router.navigateToRoute("lace-thread-dimension-list");
          })
          .catch(err => this.errorService.handleError(err));
      }
    ); 
  }
  
  gotoParentView(){
    this.eventAggregator.publish("laceThreadDimensionListReset", 1);
    this.router.navigateToRoute("lace-thread-dimension-list");   
  }
}
