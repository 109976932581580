import { DeleteDialogService } from 'services/delete-dialog-service';
import { ErrorService } from "services/error-service";
import { Router } from "aurelia-router";
import { autoinject } from "aurelia-framework";
import { ImpregnationMethodService } from "services/impregnation-method-service";
import { Models } from "models/core";
import { ToastService } from "services/toast-service";
import { EventAggregator } from "aurelia-event-aggregator"; 

@autoinject
export class ImpregnationMethodDetail {
  private impregnationMethod: Models.ImpregnationMethod;

  constructor(
    private impregnationMethodService: ImpregnationMethodService,
    private errorService: ErrorService,
    private router: Router,
    private toastService: ToastService,
    private eventAggregator: EventAggregator,
    private deleteDialogService: DeleteDialogService
  ) {}

  activate(params) {
    this.impregnationMethodService
      .get(params.Id)
      .then(impregnationMethod => {
        this.impregnationMethod = impregnationMethod;
      })
      .catch(err => this.errorService.handleError(err));
  }

  updateImpregnationMethod() {
    this.impregnationMethodService
      .put(this.impregnationMethod, this.impregnationMethod.Id)
      .then(res => {
        this.eventAggregator.publish("impregnationMethodListReset", 1);
        this.toastService.showSuccess("impregnationMethod.updated");
        this.router.navigateToRoute("impregnation-method-list");
      })
      .catch(err => this.errorService.handleError(err));
  }

  deleteImpregnationMethod() {
    this.deleteDialogService.confirmBeforeDelete(
      () => {
        this.impregnationMethodService
          .delete(this.impregnationMethod.Id)
          .then(res => {
            this.eventAggregator.publish("impregnationMethodListReset", 1);
            this.toastService.showSuccess("impregnationMethod.deleted");
            this.router.navigateToRoute("impregnation-method-list");
          })
          .catch(err => this.errorService.handleError(err));
        }
      );
  }
  gotoParentView(){
    this.eventAggregator.publish("impregnationMethodListReset", 1);
    this.router.navigateToRoute("impregnation-method-list");   
  }
}
