import { ErrorService } from "services/error-service";
import { Router } from "aurelia-router";
import { Models } from "models/core";
import { autoinject } from "aurelia-framework";
import { ToastService } from "services/toast-service";
import { EventAggregator } from "aurelia-event-aggregator";
import { LoopPositionVerticalService } from "services/loop-position-vertical-service";


@autoinject
export class LoopPositionVerticalNew {
  private looppositionvertical: Models.LoopPositionVertical = new Models.LoopPositionVertical();

  constructor(
    private loopPositionVerticalService: LoopPositionVerticalService, 
    private router: Router,
    private toastService: ToastService,
    private errorService: ErrorService,
    private eventAggregator: EventAggregator
  ) {}

  createLoopPositionVertical() {
    this.loopPositionVerticalService
      .post(this.looppositionvertical)
      .then(res => {
        this.eventAggregator.publish("loopPositionVerticalListReset", 1);
        this.toastService.showSuccess("looppositionvertical.created");
        this.router.navigateToRoute("loop-position-vertical-detail", { Id: res.Id });
      })
      .catch(err => this.errorService.handleError(err));
  }
}
