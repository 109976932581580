import { autoinject, bindable } from 'aurelia-framework';
import { Models } from 'models/core';
import { ErrorService } from 'services/error-service';
import { ProductService } from 'services/product-service';

@autoinject
export class LoopTypeForm {
  @bindable() private looptype: Models.LoopType;

  private products: Array<Models.Product>;

  constructor(private errorService: ErrorService, private productService: ProductService) {}

  protected bind() {
    void this.getAllProducts();
  }

  private async getAllProducts() {
    try {
      this.products = await this.productService.getAllCached();
    } catch (error) {
      this.errorService.handleError(error);
    }
  }

  protected setNavisionProductId(event: { detail: { value: number } }) {
    const value = event.detail.value;
    this.looptype.NavisionProductId = value;
    this.setProductName(value);
  }

  protected setDeltaProductId(event: { detail: { value: number } }) {
    const value = event.detail.value;
    this.looptype.DeltaProductId = value;
    this.setProductName(value);
  }

  private setProductName(productId: string | number) {
    if (!this.looptype.Name || this.looptype.Name === '') {
      const product = this.products.find((x) => +x.Id == +productId);
      if (product) {
        this.looptype.Name = product.Name;
        this.looptype.NameEn = product.Name;
        this.looptype.NameEs = product.Name;
      }
    }
  }
}
