import { autoinject, bindable } from 'aurelia-framework';
import './uptake-factor-row.scss';
import { Models } from 'models/core';
import { MaterialService } from 'services/material-service';
import { AsyncErrorHandler } from 'lib/ui';
import { SearchResult } from 'views/mooring/mooring-salesorder/search-box';
import { ValidationController, Validator } from 'aurelia-validation';
import { StandardValidationBuilder } from 'lib/validation';

const randomId = (suffix: string) => `${Math.random().toString(36).substring(7)}_${suffix}`;
@autoinject
export class UptakeFactorRow {
  @bindable
  protected materialUptakeFactor?: {
    MaterialId: number;
    UptakeFactor: number;
  };

  @bindable
  protected onRemove?: () => void;

  @bindable
  protected validationController: ValidationController;

  private materials: Array<Models.Material> = [];

  protected materialFormId = randomId('materialId');
  protected uptakeFormId = randomId('uptakeId');
  protected rowId = randomId('rowId');

  constructor(
    protected element: Element,
    private materialService: MaterialService,
    private validaion: StandardValidationBuilder,
    protected validator: Validator
  ) {}

  protected applyValidationRules() {
    this.validaion
      .with(this.materialUptakeFactor, this.rowId)
      .required('MaterialId')
      .required('UptakeFactor')
      .min('UptakeFactor', 0)
      .max('UptakeFactor', 10)
      .done();

    this.validationController.addObject(this.materialUptakeFactor);
  }

  @AsyncErrorHandler
  protected async attached() {
    await this.getAllMaterials();
    this.applyValidationRules();
  }

  @AsyncErrorHandler
  private async getAllMaterials() {
    this.materials = await this.materialService.getAllCached();
  }

  protected async searchHandler(input: string) {
    await new Promise((resolve) => setTimeout(resolve, 100));
    return this.materials
      .filter((material) => {
        return material.Name.toLowerCase().includes(input.toLowerCase());
      })
      .map((material) => {
        return {
          id: material.Id,
          title: material.Name,
          return: material,
        } satisfies SearchResult;
      });
  }

  protected async getItemDetails(id: number) {
    if (!this.materials?.length) {
      await this.getAllMaterials();
    }
    const material = this.materials.find((material) => material.Id === id);
    return {
      id,
      title: material.Name,
    };
  }

  protected itemSelected(item: SearchResult) {
    this.materialUptakeFactor.MaterialId = item.id;
  }

  protected remove() {
    this.validationController.removeObject(this.materialUptakeFactor);
    this.onRemove?.();
  }
}
