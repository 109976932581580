import { autoinject, bindable } from 'aurelia-framework';
import { Models } from 'models/core';
import { ErrorService } from 'services/error-service';
import { ProductService } from 'services/product-service';

@autoinject
export class ImpregnationMethodForm {
  @bindable() private impregnationMethod: Models.ImpregnationMethod;

  constructor(
    private errorService: ErrorService
  ) { }
}
